/* eslint-disable jsx-a11y/alt-text */ // alt is inside the props
import { forwardRef, useEffect, useState } from 'react'
import type { ImageLoader, ImageProps } from 'next/image'
import Image from 'next/image'

import env from '@beam-australia/react-env'
import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import queryString from 'query-string'

const knaufImageLoader: ImageLoader = ({ src, width, quality }) =>
  queryString.stringifyUrl({
    url: src,
    query: {
      wid: width,
      qlt: quality ?? 100,
      fmt: 'webp',
      fit: 'wrap',
    },
  })

type KnaufImageProps = ImageProps & {
  damId?: string
  fixedHeight?: number
} & QaHook

export const KnaufImage = forwardRef<HTMLImageElement, KnaufImageProps>((props, ref) => {
  const { src, damId, dataCy, fixedHeight, ...restProps } = props
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [src])

  return (
    <Image
      ref={ref}
      {...restProps}
      dam-id={damId}
      data-cy={dataCy}
      loader={knaufImageLoader}
      src={error ? env('BROKEN_IMAGE_ICON_URL') : src}
      onError={() => setError(true)}
      style={{
        objectFit: 'contain',
        ...restProps.style,
      }}
      {...(fixedHeight && {
        width: fixedHeight,
        height: fixedHeight,
      })}
    />
  )
})
